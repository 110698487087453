import { createSlice } from '@reduxjs/toolkit';

const isBrowser = typeof window !== 'undefined';

export const subModalSlice = createSlice({
    name: 'subModal',
    initialState: {
        show: false,
        timeout: 0
    },
    reducers: {
        show: state => {
            state.show = true
        },
        hide: state => {
            state.show = false
        },
        setModalTimeout: (state, action) => {
            state.timeout = action.payload;
        }
    }
});

const canShowModal = user => {
    if (user?.subscribed) return false;
    let lastTimeModalOpened = isBrowser ? localStorage.getItem('subscription_modal_closed') : false;
    if (!lastTimeModalOpened) return true;

    const [year, month, day] = lastTimeModalOpened.split("-");
    lastTimeModalOpened = new Date(year, month - 1, day);
    const todaysDate = new Date();
    const oneDayInMillis = 24 * 60 * 60 * 1000;

    return todaysDate.getTime() - lastTimeModalOpened.getTime() >= oneDayInMillis;
};

export const show = user => dispatch => {
    if (user?.subscribed) return;
    const canOpen = canShowModal(user);
    if (canOpen) return dispatch(subModalSlice.actions.show());
}
export const hide = () => dispatch => dispatch(subModalSlice.actions.hide());
export const setModalTimeout = timeout => dispatch => dispatch(subModalSlice.actions.setModalTimeout(timeout));

export default subModalSlice.reducer;
